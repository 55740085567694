define("discourse/plugins/discourse-patreon/discourse/controllers/admin-plugins-patreon", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/decorators", "discourse/lib/get-url", "discourse-i18n", "discourse/plugins/discourse-patreon/discourse/models/filter-rule"], function (_exports, _controller, _object, _service, _ajax, _ajaxError, _decorators, _getUrl, _discourseI18n, _filterRule) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsPatreonController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    editing = (() => _filterRule.default.create({
      group_id: null
    }))();
    prettyPrintReward(reward) {
      return `$${reward.amount_cents / 100} - ${reward.title}`;
    }
    rewardsNames() {
      return Object.values(this.rewards).filter(r => r.id >= 0).map(r => this.prettyPrintReward(r));
    }
    static #_2 = (() => dt7948.n(this.prototype, "rewardsNames", [(0, _decorators.default)("rewards")]))();
    save() {
      const rule = this.get("editing");
      const model = this.get("model");
      rule.set("group", this.groups.find(x => x.id === parseInt(rule.get("group_id"), 10)));
      rule.set("rewards_ids", Object.values(this.rewards).filter(v => rule.get("reward_list").includes(this.prettyPrintReward(v))).map(r => r.id));
      (0, _ajax.ajax)("/patreon/list.json", {
        method: "POST",
        data: rule.getProperties("group_id", "rewards_ids")
      }).then(() => {
        let obj = model.find(x => x.get("group_id") === rule.get("group_id"));
        const rewards = rule.get("reward_list").filter(Boolean);
        if (obj) {
          obj.set("reward_list", rewards);
          obj.set("rewards", rewards);
          obj.set("rewards_ids", rule.rewards_ids);
        } else {
          model.pushObject(_filterRule.default.create({
            group: rule.get("group.name"),
            rewards
          }));
        }
        this.set("editing", _filterRule.default.create({
          group_id: null
        }));
      }).catch(_ajaxError.popupAjaxError);
    }
    static #_3 = (() => dt7948.n(this.prototype, "save", [_object.action]))();
    delete(rule) {
      const model = this.get("model");
      (0, _ajax.ajax)("/patreon/list.json", {
        method: "DELETE",
        data: rule.getProperties("group_id")
      }).then(() => {
        let obj = model.find(x => x.get("group_id") === rule.get("group_id"));
        model.removeObject(obj);
      }).catch(_ajaxError.popupAjaxError);
    }
    static #_4 = (() => dt7948.n(this.prototype, "delete", [_object.action]))();
    updateData() {
      this.set("updatingData", true);
      (0, _ajax.ajax)("/patreon/update_data.json", {
        method: "POST"
      }).catch(_ajaxError.popupAjaxError).finally(() => this.set("updatingData", false));
      this.messageBus.subscribe("/patreon/background_sync", () => {
        this.messageBus.unsubscribe("/patreon/background_sync");
        this.set("updatingData", false);
        const refreshUrl = (0, _getUrl.default)("/admin/plugins/patreon");
        this.dialog.alert({
          message: (0, _discourseI18n.i18n)("patreon.refresh_page"),
          didConfirm: () => window.location.pathname = refreshUrl,
          didCancel: () => window.location.pathname = refreshUrl
        });
      });
    }
    static #_5 = (() => dt7948.n(this.prototype, "updateData", [_object.action]))();
  }
  _exports.default = AdminPluginsPatreonController;
});